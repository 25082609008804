var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar",attrs:{"data-anima":"top"},on:{"click":function($event){$event.stopPropagation();}}},[(!_vm.isMobile)?_c('div',{staticClass:"navbar-item-content"},[(_vm.panelLogo)?_c('img',{staticClass:"navbar-logo",attrs:{"src":_vm.panelLogo},on:{"click":function($event){return _vm.$router.push(`/home`)}}}):_vm._e(),_c('span',{staticClass:"navbar-title",class:`default-font-${_vm.$store.state.metas.metas.default_font}`,on:{"click":function($event){return _vm.$router.push(`/home`)}}},[_vm._v(_vm._s(_vm.panelName)+" ")]),_c('span',{key:'/home',class:`navbar-item navbar-item-${_vm.isActive(
        '/home',
      )}-${_vm.theme}`,on:{"click":function($event){return _vm.$router.push(`/home`)}}},[_vm._v(" "+_vm._s(_vm.$t("navbar.start"))+" ")]),_c('span',{key:'/home?filter=meus-cursos',class:`navbar-item navbar-item-${_vm.isActive(
        '/home?filter=meus-cursos',
      )}-${_vm.theme}`,on:{"click":function($event){return _vm.$router.push(`/home?filter=meus-cursos`)}}},[_vm._v(" "+_vm._s(_vm.$t("navbar.my_courses"))+" ")]),(_vm.likeStudent)?_vm._l((_vm.menuItemsStudent),function(item){return _c('span',{key:item.path,class:`navbar-item navbar-item-${_vm.isActive(item.path)}-${_vm.theme}`,on:{"click":function($event){return _vm.$router.push(`/${item.path}`)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}):_vm._l((_vm.menuItemsAdmin.filter((item) =>
          item.permission.includes(_vm.role),
        )),function(item){return _c('span',{key:item.path,class:`navbar-item navbar-item-${_vm.isActive(item.path)}-${_vm.theme}`,on:{"click":function($event){return _vm.$router.push(`${item.path}`)}}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2):_vm._e(),(_vm.getIsMobile && _vm.$route.name !== 'Home')?_c('div',{staticClass:"title-mobile"},[_c('div',{staticClass:"btn-back-navbar",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg',{attrs:{"width":"10","height":"18","viewBox":"0 0 10 18","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9 1L1 9L9 17","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('span',[_vm._v(" "+_vm._s(_vm.$route.name)+" ")]),_c('div',{staticStyle:{"width":"35px"}})]):_vm._e(),_c('FillHorizontal'),_c('Menu')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }